import { createRouter, createWebHashHistory } from 'vue-router';

export const Layout = () => import('@/Layout/index.vue');

// 静态路由
export const constantRoutes = [
    {
        path: '/',
        component: () => import('@/views/MAI/index.vue'),
        meta: {
            title: 'MAI',
        },
    },
    {
        path: '/AIDrawe',
        component: () => import('@/views/AIDrawe/index.vue'),
        meta: {
            title: 'AI绘图',
        },
    },
    {
        path: '/AIHelper',
        component: () => import('@/views/AIHelper/index.vue'),
        meta: {
            title: 'AI助手',
        },
    },
    {
        path: '/AIPPT',
        component: () => import('@/views/AIPPT/index.vue'),
        meta: {
            title: 'AI PPT',
        },
    },
    {
        path: '/AITable',
        component: () => import('@/views/AITable/index.vue'),
        meta: {
            title: 'AI表格',
        },
    },
    {
        path: '/AIWrite',
        component: () => import('@/views/AIWrite/index.vue'),
        meta: {
            title: 'AI写作',
        },
    },
];

/**
 * 创建路由
 */
const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes,
    // 刷新时，滚动条位置还原
    scrollBehavior: () => ({ left: 0, top: 0 }),
});

/**
 * 重置路由
 */
export function resetRouter() {
    // router.replace({ path: '/login' });
}

export default router;

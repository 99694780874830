<template>
  <el-footer>内容由AI模型输出，仅供参考，AI服务均在国内，后台不记录用户数据，保护用户数据安全。</el-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.el-footer {
  background-color: #fff;
  color: rgb(69, 69, 69);
  font-size: 12px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  width: 100%;
}
</style>
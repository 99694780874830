import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', {
    state: () => ({
        isCollapse: false,
    }),
    actions: {
        setCollapse(isCollapse) {
            this.isCollapse = isCollapse
        }
    }
})
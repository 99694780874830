<template>
  <el-header style="font-size: 12px">
    <div class="collapseBtn">
      <el-icon @click="menuStore.setCollapse(true)" v-if="!menuStore.isCollapse"><ArrowLeft/></el-icon>
      <el-icon @click="menuStore.setCollapse(false)" v-else><ArrowRight/></el-icon>
    </div>
<!--    <div class="right-button-group">-->
<!--      <el-icon @click="setExitFullScreen"><SemiSelect/></el-icon>-->
<!--      <el-icon @click="setFullScreen"><FullScreen /></el-icon>-->
<!--      <el-icon @click="closePage"><CloseBold/></el-icon>-->
<!--    </div>-->
    <div class="toolbar">
      <el-icon><img class="icon" :src="Bell"></el-icon>
      <el-icon><img class="icon" :src="feedback"></el-icon>
    </div>
  </el-header>
</template>

<script setup>
import { CloseBold, FullScreen, SemiSelect, ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import Bell from '@/assets/icon/Bell.png'
import feedback from '@/assets/icon/feedback.png'
import { useMenuStore } from "../../store"

const menuStore = useMenuStore()
const isFullScreen = ref(false)

const setFullScreen = () => {
  if(isFullScreen.value) return
  document.documentElement.requestFullscreen()
  isFullScreen.value = true
}
const setExitFullScreen = () => {
  if(!isFullScreen.value) return
  document.exitFullscreen()
  isFullScreen.value = false
}
const closePage = () => {
  window.opener = null
  window.open("about:blank", "_top").close()
}
</script>

<style scoped>
.el-header {
  display: block;
  background-color: #fff;
  width: 100%;
  height: 64px;
  line-height: 64px;
}
.icon {
  display: inline-block;
  width: 16px;
}
.el-icon {
  color: rgb(66, 66, 66);
  cursor: pointer;
  font-size: 16px;
}
.el-header > div{
  line-height: 64px;
  display: inline-block;
}
.toolbar {
  float:right;
}
.toolbar .el-icon{
  margin-right: 20px;
}
.right-button-group {
  float:right;
  line-height: 64px;
}
.right-button-group > .el-icon {
  margin-left: 20px;
}
.collapseBtn {
  width: 32px;
  height:32px;
  text-align:center;
  line-height: 32px;
}
.collapseBtn:hover{
  /*border: 1px solid #ddd;*/
  color: steelblue;
}
</style>
<template>
  <el-aside width="collapse" height="100vh">
    <el-scrollbar>

      <el-menu background-color="rgba(241, 244, 245, 1)" :style="{'width':isCollapse? '72px':'240px'}"  router :collapse="isCollapse">
        <div class="logo" :style="{'paddingLeft': isCollapse? '15px':'40px', 'textAlign': 'left'}">
          <img v-show="!isCollapse" :src="logo" alt="">
          <el-icon v-show="isCollapse"><img :src="logoSimple" /></el-icon>
        </div>
        <el-menu-item index="/">
            <el-icon><img class="icon" :src="MAI" /></el-icon>
            <template #title>
              <p>M-AI</p>
            </template>
        </el-menu-item>

        <el-menu-item index="/AIWrite">
          <el-icon><img class="icon" :src="AIWRITE" /></el-icon>
          <template #title>AI 写作</template>
        </el-menu-item>
        <el-menu-item index="/AIPPT">
          <el-icon><img class="icon" :src="AIPPT" /></el-icon>
          <template #title>AI PPT</template>
        </el-menu-item>
        <el-menu-item index="/AITable">
          <el-icon><img class="icon" :src="AITABLE" /></el-icon>
          <template #title>AI 表格</template>
        </el-menu-item>
        <el-menu-item index="/AIHelper">
          <el-icon><img class="icon" :src="AIHELPER" /></el-icon>
          <template #title>AI智能体</template>
        </el-menu-item>
        <el-menu-item index="/AIDrawe">
          <el-icon><img class="icon" :src="AIDRAWE" /></el-icon>
          <template #title>AI绘图</template>
        </el-menu-item>
        <el-menu-item>
          <el-icon><img class="icon" :src="muban" /></el-icon>
          <template #title>模板</template>
        </el-menu-item>
        <el-menu-item>
          <el-icon><img class="icon" :src="daohang" /></el-icon>
          <template #title>导航</template>
        </el-menu-item>
        <div class="bottom-menu" :style="bottomStyle">
          <el-menu-item>
            <el-icon><img class="icon" :src="lishi" /></el-icon>
            <template #title>历史记录</template>
          </el-menu-item>
          <el-menu-item>
            <el-icon><img class="icon" :src="yonghu" /></el-icon>
            <template #title>用户资料</template>
          </el-menu-item>
          <el-menu-item>
            <el-icon><img class="icon" :src="jiaocheng" /></el-icon>
            <template #title>试用指南</template>
          </el-menu-item>
          <el-menu-item>
            <el-icon><img class="icon" :src="shezhi" /></el-icon>
            <template #title>设置</template>
          </el-menu-item>
        </div>
      </el-menu>

    </el-scrollbar>
  </el-aside>
</template>

<script setup>
import { useMenuStore } from '@/store/index.js'
import { useResizeObserver } from '@vueuse/core'
import logoSimple from '@/assets/logo.png'
import logo from '@/assets/taokLogo.png'
import MAI from '@/assets/icon/Bold_Duotone___Messages__Conversation___Dialog_2@2x.png'
import AIWRITE from '@/assets/icon/Bold_Duotone___Notes___Documents@2x.png'
import AIPPT from '@/assets/icon/Bold_Duotone___Business__Statistic___Presentation_Graph@2x.png'
import AITABLE from '@/assets/icon/Bold_Duotone___Time___Calendar_Add@2x.png'
import AIHELPER from '@/assets/icon/Bold_Duotone___Astronomy___Atom@2x.png'
import AIDRAWE from '@/assets/icon/Bold_Duotone___Design__Tools___Ruler_Cross_Pen@2x.png'
import muban from '@/assets/icon/Bold_Duotone___Design__Tools___Palette@2x.png'
import daohang from '@/assets/icon/Bold_Duotone___Settings__Fine_Tuning___Widget_Add@2x.png'
import yonghu from '@/assets/icon/Outline___School___Bookmark_Opened@2x.png'
import shezhi from '@/assets/icon/Outline___Settings__Fine_Tuning___Settings_Minimalistic@2x.png'
import jiaocheng from '@/assets/icon/Outline___Map___Location___Compass_Square@2x.png'
import lishi from '@/assets/icon/Outline___Messages__Conversation___Inbox_Line@2x.png'

const menuStore = useMenuStore()
let isCollapse = computed(() => menuStore.isCollapse)
const bottomStyle = ref({})
useResizeObserver(document.documentElement, (entries) => {
  const entry = entries[0]
  const { width, height } = entry.contentRect
  if (height >= 800) {
    bottomStyle.value = {
      position: 'absolute',
      bottom: '20px'
    }
  } else {
    bottomStyle.value = {
      position: 'relative',
      top: '20px'
    }
  }
})
</script>

<style scoped>
.el-aside {
  background-color: rgba(241, 244, 245, 1);
  color: rgba(66, 66, 66, 1);
}

.logo {
  height: 64px;
  line-height: 64px;
  /*padding-left: 20px;*/
}
.logo .el-icon {
  height: 64px;
  width: 30px;
}
.logo .el-icon > img{
  height: 32px;
  /*width: 32px;*/
}
.logo > img {
  padding-top: 16px;
  height: 32px;
  line-height:64px;
}
.el-menu {
  border-right: none;
  height: 100vh;
}
.el-menu-item [class^=el-icon] {
  width: 32px;
}
.el-menu-item {
  height: 60px;
  line-height: 60px;
  padding: 0;
  padding-left: 40px !important;
}
.icon {
  display: inline-block;
  width: 24px;
  margin-right:12px;
  overflow: hidden;
}
.icon > .icon{
  filter: drop-shadow(30px 0 0);
  border-right: 30px solid transparent;
  position: relative;
  left: -30px;
  top: -16px;
  /*-webkit-filter: drop-shadow(30px 0 0 white)*/
}
.bottom-menu {
  width: 100%;

}
</style>